import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import cx from 'classnames'
import Button from 'components/button'
import Table from 'components/table/beta'
import Modal from 'components/modal'
import { getTooltipList } from 'components/utils/tooltip'
import { renderOwnerCell } from 'components/utils/owner'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { editLockContext } from 'contexts/edit-lock'
import { useStore } from 'store'
import { getAlertTemplatesPaginated } from './actions'
import './style.scss'

const limitWidthName = window.screen.width <= 1440 ? 40 : 100
const limitWidthExpression = window.screen.width <= 1440 ? 80 : 200
const DEFAULT_SORT = [{ id: 'updatedAt', desc: true }]

const AlertTemplatesModule = ({
  onDelete,
  hasViewAccess,
  hasEditAccess,
  hasCreateAccess,
}) => {
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const {
    alertTemplates: { listPaginated: alertTemplates },
  } = state

  const [itemToDelete, setItemToDelete] = useState(null)
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [tableState, setTableState] = useState({
    sort: [...DEFAULT_SORT],
    pagination: {
      page: 0,
      size: 10,
    },
  })

  /** Edit locks */
  const { setEntityType, locks, EDIT_LOCK_ENTITIES } =
    useContext(editLockContext)

  /** Tell the Edit Lock context what entity we're currently on */
  useEffect(() => {
    setEntityType(EDIT_LOCK_ENTITIES.ALERT_TEMPLATE)

    /** It's important to clear the type upon leaving the page */
    return () => {
      setEntityType(null)
    }
  }, [setEntityType])

  /** Disable loader only after the locks have been found for the entities */
  useEffect(() => {
    setLoading(!locks)
  }, [locks])

  const fetchData = () => {
    setLoading(true)
    getAlertTemplatesPaginated(
      dispatch,
      {},
      tableState.pagination,
      tableState.sort
    )
      .then(({ total }) => {
        setTotal(total)
      })
      .finally(() => setLoading(false))
  }

  useEffect(fetchData, [JSON.stringify(tableState)])

  const onSortChanged = (sort) => {
    if (!sort.length) {
      setTableState((prevTableState) => ({
        ...prevTableState,
        sort: [...DEFAULT_SORT],
      }))
      return
    }
    setTableState((prevTableState) => ({ ...prevTableState, sort }))
  }

  const onChangeTableView = ({ pageIndex, pageSize, sort }) => {
    setTableState({
      ...tableState,
      sort,
      pagination: {
        page: pageIndex,
        size: pageSize,
      },
    })
  }

  const columns = [
    {
      header: '',
      id: 'actions',
      cell: (cell) => {
        const { key, _id } = cell.row.original
        const locked = locks?.[_id]

        if (!hasEditAccess && !hasViewAccess) {
          return null
        }

        return (
          <div className="table__actions">
            <div
              className={cx('', {
                table__view: !locked && hasViewAccess && !hasEditAccess,
                table__edit: !locked && hasEditAccess,
                table__lock: locked,
              })}
              onClick={() => !locked && navigate(`/alert-templates/${key}`)}
            />
          </div>
        )
      },
      tooltip: (row) => {
        /** Edit lock */
        const locked = locks?.[row._id]

        return locked
          ? getTooltipList(locked.tooltipHeader, [locked.tooltipText])
          : null
      },
      minSize: 55,
      maxSize: 55,
    },
    {
      header: 'Template Name',
      id: 'name',
      accessorKey: 'name',
      accessorFn: (row) =>
        `${row.name?.substring(0, limitWidthName)}${
          row.name?.length >= limitWidthName ? '...' : ''
        }`,
      tooltip: (row) => {
        return row.name?.length >= limitWidthName
          ? getTooltipList('Name', [row.name])
          : null
      },
      size: 150,
      minSize: 150,
    },
    {
      header: 'Expression',
      id: 'expression',
      accessorKey: 'expression',
      cell: (cell) =>
        `${cell.row.original.expression?.substring(0, limitWidthExpression)}${
          cell.row.original.expression?.length >= limitWidthExpression
            ? '...'
            : ''
        }`,
      tooltip: (row) => {
        return row.expression?.length >= limitWidthExpression
          ? getTooltipList('Expression', [row.expression])
          : null
      },
      size: 300,
      minSize: 300,
    },
    {
      header: 'Global',
      id: 'isGlobal',
      accessorKey: 'isGlobal',
      accessorFn: (row) => (row.isGlobal ? 'Yes' : 'No'),
      size: 80,
      minSize: 80,
    },
    {
      header: 'Associated Alerts',
      id: 'associatedAlerts',
      accessorKey: 'alerts',
      cell: (cell) => cell.row?.original.alertCount || 0,
      tooltip: (row) => {
        return row?.alerts?.length
          ? getTooltipList(
              'Associated Alerts',
              row.alerts.map(({ name }) => name)
            )
          : null
      },
      size: 80,
      minSize: 80,
      sortType: 'number',
    },
    {
      header: 'Owner',
      id: 'owner',
      accessorKey: 'owner',
      cell: renderOwnerCell,
      tooltip: (row) => {
        if (!row.owner.active) {
          return 'Inactive User'
        }
      },
      sortType: 'alphanumeric',
    },
    {
      header: 'Date Modified',
      id: 'updatedAt',
      accessorFn: (row) => new Date(row.updatedAt),
      cell: (cell) => {
        const { _id, deletable, updatedAt } = cell.row.original

        /** Edit lock */
        const locked = locks?.[_id]

        return (
          <div className="align-row" style={{ width: '100%' }}>
            {format(new Date(updatedAt), 'MM/dd/yyyy')}
            {!locked && deletable && onDelete && (
              <DeleteIcon
                className="table__delete fill-red"
                alt={'delete'}
                onClick={() => setItemToDelete(_id)}
              />
            )}
          </div>
        )
      },
      size: 130,
      minSize: 130,
    },
  ]

  return (
    <div className="alert-templates page">
      {itemToDelete && onDelete && (
        <Modal
          opened={!!itemToDelete}
          button={
            <Button
              value="Delete"
              onClick={() => {
                onDelete(itemToDelete)
                setItemToDelete(null)
              }}
            />
          }
          buttonSecondary={
            <Button
              value={'Cancel'}
              onClick={() => setItemToDelete(null)}
              secondaryGray
            />
          }
          heading={`Are you sure you want to delete "${
            alertTemplates.find((template) => template._id === itemToDelete)
              .name
          }"?`}
          className="alert-categories__modal"
        />
      )}

      <Helmet>
        <title>Alert Templates</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        Alert Templates
        {hasCreateAccess ? (
          <div className="heading__buttons">
            <Button
              value="Create Alert Template"
              onClick={() => navigate('/alert-templates/new')}
            />
          </div>
        ) : null}
      </div>
      <Table
        columns={columns}
        data={alertTemplates}
        showPagination={true}
        manualPagination={true}
        serverSideFetch={true}
        autoResetPageIndex={false}
        tableContainer="alert-templates"
        paginationValues={[10, 20, 50, 100]}
        initialState={{
          sortBy: tableState.sort,
          pagination: {
            pageIndex: tableState.pagination.page,
            pageSize: tableState.pagination.size,
          },
        }}
        onSortChanged={onSortChanged}
        onChangeTableView={onChangeTableView}
        manualCount={total}
        pageCount={Math.ceil(total / tableState.pagination.size)}
        className="alert-templates-table"
        loading={loading}
      />
    </div>
  )
}

AlertTemplatesModule.propTypes = {
  alertTemplates: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  hasViewAccess: PropTypes.bool,
  hasEditAccess: PropTypes.bool,
  hasCreateAccess: PropTypes.bool,
}

export default AlertTemplatesModule
