import PropTypes from 'prop-types'
import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  Navigate,
  useParams,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  ScrollRestoration,
} from 'react-router-dom'

import useSession from 'modules/session'
import { buildLoginRedirectUrl } from 'components/utils/url'

import { StateProvider } from 'store.js'
import SecurityProvider from 'contexts/security'
import AppConfig from 'components/app-config/index'

/* Pages */
import LandingPage from 'pages/index'
import LoginPage from 'pages/login'
import SignUpPage from 'pages/sign-up'
import ForgotPasswordPage from 'pages/forgot-password'
import ResetPasswordPage from 'pages/reset-password'
import MultiFactorAuthPage from 'pages/multi-factor-auth'
import MultiFactorSetupPage from 'pages/multi-factor-setup'
import PlaygroundPage from 'pages/playground'
import Page404 from 'pages/404'
import UnauthorizedPage from 'pages/unauthorized'
import TermsOfUsePage from 'pages/terms-of-use'
import ConfigPage from 'pages/config'
import UserManagement from 'pages/users'
import UserAssign from 'pages/user-assign'
import TeamsIndex from 'pages/teams'
import TeamCreateEdit from 'pages/teams/create-edit'
import MicrosoftAds from 'pages/microsoft-ads'
import VariableMetricsIndex from 'pages/metric-variables'
import VariableMetricsManagement from 'pages/metric-variables/create-edit'
import AlertTemplatesPage from 'pages/alert-templates'
import AlertsIndexPage from 'pages/alerts-index'
import AlertPage from 'pages/alert'
import AlertCategoriesPage from 'pages/alert-categories'
import CalculatedMetricsPage from 'pages/calculated-metrics'
import MicrosoftVerification from 'pages/microsoft-verification'
import MyAccount from 'pages/my-account'
import ReportingsPage from 'pages/reportings'
import AlertTriggerDetailsPage from 'pages/alert-trigger-details'
import ActiveAlertTriggersPage from 'pages/active-alert-triggers'
import ArchivedAlertTriggersPage from 'pages/archived-alert-triggers'
import AlertThresholdsPage from 'pages/alert-thresholds'
import SandboxPage from 'pages/sandbox'
import PermissionGroupsIndex from 'pages/permission-groups'
import PermissionGroupManagement from 'pages/permission-groups/create-edit'
// import TraqTemplatesPage from 'pages/traq-templates'
// import TraqDashboardPage from 'pages/traq-dashboard'
// import AnalysisHubPage from 'pages/analysis-hub'
import GlobalSettings from 'modules/platform-settings'
import Security from 'modules/platform-settings/pages/security'
import SlackColors from 'modules/platform-settings/pages/slack-colors'
import EntityCleanup from 'modules/platform-settings/pages/daily-entity-cleanup'
// import GlobalReportsSettings from 'modules/platform-settings/pages/global-reports'
import NamingConventions from 'modules/platform-settings/pages/naming-conventions'
import OfflineData from 'modules/platform-settings/pages/offline-data'
// import DataStoreGlobal from 'modules/platform-settings/pages/data-store'
import ChangeHistory from 'modules/platform-settings/pages/change-history'
// import DataStoreGlobalModule from 'modules/data-store-global/pages/index'
import { LegalViewPage } from 'pages/legal-view'
import { LegalPage } from 'pages/legal'
import { LegalItemPage } from 'pages/legal-item'
import { LegalSectionPage } from 'pages/legal-section'
import NotificationGroups from 'pages/notification_groups'
// import { GlobalReports } from 'pages/global-reports'
// import { GlobalReportsForm } from 'pages/global-reports-form'
import { GlobalReportsDashboard } from 'pages/global-reports-dashboard'
// import { GlobalReportView } from 'pages/global-report-view'
// import GlobalReportsCache from 'pages/global-reports-cache'
import E2eAlertExecutor from 'pages/e2e-alert-executor'
import HomePage from 'modules/homepage/index'
import CompanyOutlet from 'pages/client/client-outlet'
import BusinessUnits from 'pages/client/business-units'
import CompanyAddEdit from 'pages/client/company'
import AccountCreateEdit from 'pages/client/account'
import CompanyManagement from 'pages/client/companies'
// import DataStorePage from 'pages/data-store'
import ImportUsers from 'pages/import-users'

import Loader from 'components/loader'

/* Constants */
import { access } from '@decision-sciences/qontrol-common'
import { STORAGE_KEY_ALL_COMPANIES } from './modules/companies/reducers'

const { hasAccess, hasCompanyAccess, routeAccess } = access

const env = process.env.NODE_ENV
const isLocal = !env || env === 'development'
export const NOT_FOUND_ROUTE = '/404'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <SecurityProvider>
        <ScrollRestoration />
        <Outlet />
        <AppConfig />
      </SecurityProvider>
    ),
    children: [
      {
        path: '/',
        // user has to be logged in for these routes.
        element: (
          <PrivateRoute>
            <LandingPage />
          </PrivateRoute>
        ),
        children: [
          {
            path: '/',
            index: true,
            element: (
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            ),
          },
          {
            path: 'playground',
            element: <PlaygroundPage />,
          },
          {
            path: 'unauthorized',
            element: <UnauthorizedPage />,
          },
          {
            path: '/my-account/:section',
            element: <MyAccount />,
          },
          {
            path: NOT_FOUND_ROUTE,
            element: <Page404 />,
          },
          // Redirect any other route to 404
          {
            path: '*',
            element: <Page404 />,
          },

          // Microsoft Verification callback
          {
            path: '/microsoft/callback',
            element: <MicrosoftVerification />,
          },

          // Super Admin Routes
          {
            path: 'config',
            element: (
              <SuperAdminRoute>
                <ConfigPage />
              </SuperAdminRoute>
            ),
          },
          {
            path: 'config/:company',
            element: (
              <SuperAdminRoute>
                <ConfigPage />
              </SuperAdminRoute>
            ),
          },
          {
            path: 'sandbox',
            element: (
              <SuperAdminRoute>
                <SandboxPage />
              </SuperAdminRoute>
            ),
          },
          {
            path: 'microsoft',
            element: (
              <SuperAdminRoute>
                <MicrosoftAds />
              </SuperAdminRoute>
            ),
          },
          {
            path: 'global-settings',
            children: [
              {
                path: '',
                element: (
                  <SuperAdminRoute>
                    <GlobalSettings />
                  </SuperAdminRoute>
                ),
                children: [
                  { path: '', element: <Navigate to={'security'} /> },
                  {
                    path: 'security',
                    element: <Security />,
                  },
                  {
                    path: 'slack-colors',
                    element: <SlackColors />,
                  },
                  {
                    path: 'daily-entity-cleanup',
                    element: <EntityCleanup />,
                  },
                  {
                    path: 'naming-conventions',
                    element: <NamingConventions />,
                  },
                  {
                    path: 'offline-data',
                    element: <OfflineData />,
                  },
                  {
                    path: 'change-history',
                    element: <ChangeHistory />,
                  },
                ],
              },
            ],
          },
          {
            path: 'global-settings/:key',
            element: (
              <SuperAdminRoute>
                <GlobalSettings />
              </SuperAdminRoute>
            ),
          },
          {
            path: 'e2e-alert-executor',
            element: (
              <SuperAdminRoute>
                <E2eAlertExecutor />
              </SuperAdminRoute>
            ),
          },

          // Logged in access Routes
          {
            path: 'users',
            element: (
              <PrivateRoute permissions={routeAccess['/users']}>
                <UserManagement />
              </PrivateRoute>
            ),
          },
          {
            path: 'users/:userId',
            element: (
              <PrivateRoute permissions={routeAccess['/users/:userId']}>
                <UserManagement />
              </PrivateRoute>
            ),
          },
          {
            path: 'assign-user/:userId',
            element: (
              <PrivateRoute permissions={routeAccess['assign-user/:userId']}>
                <UserAssign />
              </PrivateRoute>
            ),
          },
          {
            path: 'assign-user/global/:userId',
            element: (
              <PrivateRoute
                permissions={routeAccess['assign-user/global/:userId']}
              >
                <UserAssign />
              </PrivateRoute>
            ),
          },
          {
            path: 'import-users',
            element: (
              <PrivateRoute permissions={routeAccess['/import-users']}>
                <ImportUsers />
              </PrivateRoute>
            ),
          },
          {
            path: 'team-management',
            element: (
              <PrivateRoute permissions={routeAccess['/team-management']}>
                <TeamsIndex />
              </PrivateRoute>
            ),
          },
          {
            path: 'team/:teamId',
            element: (
              <PrivateRoute permissions={routeAccess['/team-management']}>
                <TeamCreateEdit />
              </PrivateRoute>
            ),
          },
          {
            path: 'client-management',
            element: (
              <PrivateRoute permissions={routeAccess['/client-management']}>
                <CompanyManagement />
              </PrivateRoute>
            ),
          },
          {
            path: 'company/:clientId?',
            element: (
              <PrivateRoute permissions={routeAccess['/company/:company?']}>
                <CompanyOutlet />
              </PrivateRoute>
            ),
            children: [
              {
                path: '',
                index: true,
                element: (
                  <PrivateRoute permissions={routeAccess['/company/:company?']}>
                    <CompanyAddEdit />
                  </PrivateRoute>
                ),
              },
              {
                path: 'business-unit/:businessUnitClientId?',
                element: (
                  <PrivateRoute
                    permissions={
                      routeAccess[
                        'company/:clientId/business-unit/:businessUnitId?'
                      ]
                    }
                  >
                    <BusinessUnits />
                  </PrivateRoute>
                ),
              },
              {
                path: 'business-unit/:businessUnitClientId?/account',
                element: (
                  <PrivateRoute
                    permissions={
                      routeAccess[
                        'company/:clientId/business-unit/:businessUnitId?/account'
                      ]
                    }
                  >
                    <AccountCreateEdit />
                  </PrivateRoute>
                ),
              },

              {
                path: 'account/:accountKey?',
                element: (
                  <PrivateRoute
                    permissions={
                      routeAccess['company/:clientId/account/:accountKey?']
                    }
                  >
                    <AccountCreateEdit />
                  </PrivateRoute>
                ),
              },
            ],
          },

          {
            path: 'variables',
            element: (
              <PrivateRoute permissions={routeAccess['/variables']}>
                <VariableMetricsIndex />
              </PrivateRoute>
            ),
          },
          {
            path: 'variables/:variableKey',
            element: (
              <PrivateRoute
                permissions={routeAccess['/variables/:variableKey?']}
              >
                <VariableMetricsManagement />
              </PrivateRoute>
            ),
          },
          {
            path: 'calculated-metrics',
            element: (
              <PrivateRoute permissions={routeAccess['/calculated-metrics']}>
                <CalculatedMetricsPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'calculated-metrics/:metricId',
            element: (
              <PrivateRoute
                permissions={routeAccess['/calculated-metrics/:metricId']}
              >
                <CalculatedMetricsPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'alert-categories',
            element: (
              <PrivateRoute permissions={routeAccess['/alert-categories']}>
                <AlertCategoriesPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'alert-templates/:alertTemplateKey?',
            element: (
              <PrivateRoute
                permissions={routeAccess['/alert-templates/:alertTemplateKey?']}
              >
                <AlertTemplatesPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'alerts',
            element: (
              <PrivateRoute permissions={routeAccess['/alerts']}>
                <AlertsIndexPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'alerts/:alertId?',
            element: (
              <PrivateRoute permissions={routeAccess['/alerts/:alertId?']}>
                <AlertPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'alerts/:alertId?/:view?',
            element: (
              <PrivateRoute
                permissions={routeAccess['/alerts/:alertId?/:view?']}
              >
                <AlertPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'alert-triggers',
            element: (
              <PrivateRoute permissions={routeAccess['/alert-triggers']}>
                <ActiveAlertTriggersPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'alert-triggers/:alertTriggerId?',
            element: (
              <PrivateRoute
                permissions={routeAccess['/alert-triggers/:alertTriggerId?']}
              >
                <AlertTriggerDetailsPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'alert-triggers/archive',
            element: (
              <PrivateRoute
                permissions={routeAccess['/alert-triggers/archive']}
              >
                <ArchivedAlertTriggersPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'alert-thresholds',
            element: (
              <PrivateRoute permissions={routeAccess['/alert-thresholds']}>
                <AlertThresholdsPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'alert-thresholds/:key?',
            element: (
              <PrivateRoute
                permissions={routeAccess['/alert-thresholds/:key?']}
              >
                <AlertThresholdsPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'legal/cms',
            element: (
              <PrivateRoute permissions={routeAccess['/legal/cms']}>
                <LegalPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'legal/cms/:id',
            element: (
              <PrivateRoute permissions={routeAccess['/legal/cms']}>
                <LegalItemPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'legal/cms/:id/section/:sectionId',
            element: (
              <PrivateRoute permissions={routeAccess['/legal/cms']}>
                <LegalSectionPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'legal/:slug',
            element: (
              <PrivateRoute permissions={routeAccess['/legal:slug']}>
                <LegalViewPage />
              </PrivateRoute>
            ),
          },
          // TODO: For the moment brief routes are just commented out, scope of AP-10057
          // {
          //   path: 'briefs',
          //   element: (
          //     <PrivateRoute permissions={routeAccess['/briefs']}>
          //       <CampaignBriefs />
          //     </PrivateRoute>
          //   ),
          // },
          // {
          //   path: `briefs/:id`,
          //   element: (
          //     <PrivateRoute permissions={routeAccess['/briefs/:id']}>
          //       <Flow />
          //     </PrivateRoute>
          //   ),
          //   handle: {
          //     mainClass: 'flow-wrapper',
          //   },
          // },
          // {
          //   path: `briefs/:id/${STEPS_MAP.DEFINE_BRIEF.path}`,
          //   element: (
          //     <PrivateRoute permissions={routeAccess['/briefs/:id']}>
          //       <Flow stepBasedOnUrl={STEPS_MAP.DEFINE_BRIEF.idx} />
          //     </PrivateRoute>
          //   ),
          //   handle: {
          //     mainClass: 'flow-wrapper',
          //   },
          // },
          // {
          //   path: `briefs/:id/${STEPS_MAP.BRIEF_DETAILS.path}`,
          //   element: (
          //     <PrivateRoute permissions={routeAccess['/briefs/:id']}>
          //       <Flow stepBasedOnUrl={STEPS_MAP.BRIEF_DETAILS.idx} />
          //     </PrivateRoute>
          //   ),
          //   handle: {
          //     mainClass: 'flow-wrapper',
          //   },
          // },
          // {
          //   path: `briefs/:id/${STEPS_MAP.CAMPAIGNS.path}`,
          //   element: (
          //     <PrivateRoute permissions={routeAccess['/briefs/:id']}>
          //       <Flow stepBasedOnUrl={STEPS_MAP.CAMPAIGNS.idx} />
          //     </PrivateRoute>
          //   ),
          //   handle: {
          //     mainClass: 'flow-wrapper',
          //   },
          // },
          // {
          //   path: `briefs/:id/${STEPS_MAP.AD_CONTENT.path}`,
          //   element: (
          //     <PrivateRoute permissions={routeAccess['/brief/:id']}>
          //       <Flow stepBasedOnUrl={STEPS_MAP.AD_CONTENT.idx} />
          //     </PrivateRoute>
          //   ),
          //   handle: {
          //     mainClass: 'flow-wrapper',
          //   },
          // },
          // {
          //   path: `briefs/:id/${STEPS_MAP.AD_SETS.path}`,
          //   element: (
          //     <PrivateRoute permissions={routeAccess['/briefs/:id']}>
          //       <Flow stepBasedOnUrl={STEPS_MAP.AD_SETS.idx} />
          //     </PrivateRoute>
          //   ),
          //   handle: {
          //     mainClass: 'flow-wrapper',
          //   },
          // },
          // {
          //   path: `briefs/:id/${STEPS_MAP.ADS.path}`,
          //   element: (
          //     <PrivateRoute permissions={routeAccess['/briefs/:id']}>
          //       <Flow stepBasedOnUrl={STEPS_MAP.ADS.idx} />
          //     </PrivateRoute>
          //   ),
          //   handle: {
          //     mainClass: 'flow-wrapper',
          //   },
          // },
          // {
          //   path: `briefs/:id/${STEPS_MAP.FINAL_REVIEW.path}`,
          //   element: (
          //     <PrivateRoute
          //       permissions={routeAccess['/briefs/:id/final-review']}
          //     >
          //       <Flow stepBasedOnUrl={STEPS_MAP.FINAL_REVIEW.idx} />
          //     </PrivateRoute>
          //   ),
          //   handle: {
          //     mainClass: 'flow-wrapper',
          //   },
          // },
          {
            path: `reports/:reportingId`,
            element: (
              <PrivateRoute permissions={routeAccess['/reports/:reportingId']}>
                <ReportingsPage />
              </PrivateRoute>
            ),
            handle: {
              mainClass: 'reporting',
            },
          },
          // {
          //   path: `global-reports`,
          //   element: (
          //     <PrivateRoute permissions={routeAccess['/global-reports']}>
          //       <GlobalReports />
          //     </PrivateRoute>
          //   ),
          // },
          // {
          //   path: `global-reports/:key`,
          //   element: (
          //     <PrivateRoute permissions={routeAccess['/global-reports/:key']}>
          //       <GlobalReportsForm />
          //     </PrivateRoute>
          //   ),
          // },
          {
            path: `tableau-dashboard`,
            element: (
              <PrivateRoute permissions={routeAccess['/tableau-dashboard']}>
                <GlobalReportsDashboard />
              </PrivateRoute>
            ),
          },
          // {
          //   path: `tableau-dashboard/:key`,
          //   element: (
          //     <PrivateRoute
          //       permissions={routeAccess['/tableau-dashboard/:key']}
          //     >
          //       <GlobalReportView />
          //     </PrivateRoute>
          //   ),
          //   handle: {
          //     mainClass: 'reporting reporting__page',
          //   },
          // },
          // {
          //   path: `cache-tableau-resources`,
          //   element: (
          //     <SuperAdminRoute>
          //       <GlobalReportsCache />
          //     </SuperAdminRoute>
          //   ),
          //   handle: {
          //     mainClass: 'reporting',
          //   },
          // },
          {
            path: `permission-groups`,
            element: (
              <PrivateRoute permissions={routeAccess['/permission-groups']}>
                <PermissionGroupsIndex />
              </PrivateRoute>
            ),
          },
          {
            path: `permission-groups/:key`,
            element: (
              <PrivateRoute
                permissions={routeAccess['/permission-groups/:key']}
              >
                <PermissionGroupManagement />
              </PrivateRoute>
            ),
          },
          {
            path: `notification-groups`,
            element: (
              <PrivateRoute permissions={routeAccess['/notification-groups']}>
                <NotificationGroups />
              </PrivateRoute>
            ),
          },
          {
            path: `notification-groups/:key`,
            element: (
              <PrivateRoute permissions={routeAccess['/notification-groups']}>
                <NotificationGroups />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'login/:slug?',
        element: <LoginPage />,
      },
      {
        path: 'sign-up',
        element: <SignUpPage />,
      },
      {
        path: 'sign-up/terms-of-use',
        element: <TermsOfUsePage />,
      },
      {
        path: 'terms-of-use/:userId',
        element: <TermsOfUsePage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: 'multi-factor-auth',
        element: <MultiFactorAuthPage />,
      },
      {
        path: 'multi-factor-setup',
        element: <MultiFactorSetupPage />,
      },
    ],
  },
])

/** App Router */
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  // State provider is on the outside since PrivateRoutes use stuff from state
  <StateProvider>
    <RouterProvider router={router} fallbackElement={<Loader />} />
  </StateProvider>
)

/**
 * Private route wrapper. Ensures private routes are accessed only by Authenticated & Allowed users
 * It checks for "requiredPermissions" dynamically based on the route
 * If no "requiredPermissions" are found for this route, anyone logged-in can access
 *
 * @param options
 * @param {String} options.children - Children
 * @param {Object[]} options.permissions - Allowed permissions for Route
 */
function PrivateRoute({ children, permissions }) {
  const [isLoggedIn, userData] = useSession()
  const params = useParams()

  checkHttps(window)
  // Check for authorization
  if (!isLoggedIn || !userData) {
    return <Navigate replace to={buildLoginRedirectUrl()} />
  }

  // Check access by permissions
  const access = hasAccess(userData, permissions)
  if (!access) {
    return <Navigate to="/unauthorized" replace />
  }

  // Check for company access (only for routes with :company or :companyId)
  const { company, clientId } = params
  if (!userData.isSuperAdmin && (company || clientId)) {
    const allCompanies = JSON.parse(
      localStorage.getItem(STORAGE_KEY_ALL_COMPANIES)
    )
    if (!hasCompanyAccess(userData, allCompanies, company || clientId)) {
      return <Navigate to="/unauthorized" replace />
    }
  }

  return children
}

/**
 * Super Admin Route Wrapper - allows access only to super admins
 *
 * @param options
 * @param {ReactDOM} options.children - Children to render
 */
function SuperAdminRoute({ children }) {
  const [isLoggedIn, userData] = useSession()

  // Check for authorization
  if (!isLoggedIn || !userData) {
    const { protocol, hostname } = window.location

    window.location =
      protocol !== 'https' && !isLocal
        ? `https://${hostname}${buildLoginRedirectUrl()}`
        : buildLoginRedirectUrl()
  }

  if (userData && userData.isSuperAdmin) {
    return children
  }

  return <Navigate replace to="/unauthorized" />
}

function checkHttps(window) {
  const { protocol, hostname, pathname } = window.location
  if (!protocol.startsWith('https') && !isLocal) {
    window.location.href = `https://${hostname}${pathname}`
  }
}

PrivateRoute.propTypes = {
  permissions: PropTypes.array,
  children: PropTypes.node.isRequired,
}

SuperAdminRoute.propTypes = {
  children: PropTypes.node.isRequired,
}
